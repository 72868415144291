<template>
  <div class="voc-card">
    <div class="review-volume-main" :class="[!npsCard  && 'border-bottom', npsCard && 'justify-content-center']">
      <div class="product-logo" :class="[vocVolume === 0 && 'hide-overflow', npsCard && 'w-100']">
        <img
          v-if="(user_preference_mode=='dark' && product.properties && product.properties.logo_dark) || (user_preference_mode=='light' && product.properties && product.properties.logo_light)"
          :src="
            user_preference_mode=='dark'
              ? product.properties.logo_dark
              : product.properties.logo_light
          "
          :alt="product.name" :title="product.name" />
        <span v-else
          class="product-name" :class="[vocVolume > 0 && 'fixed-width']">{{product.name}}</span>
      </div>
      <!-- <div v-if="vocData.trendData && vocData.trendData.length" class="voc-legends">
        <div class="voc-volume-legend voc">
              <ul>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#c2c7de" d="M12 16V2h3v14zm-6 0V0h3v16zm-6 0V6h3v10z"/></svg>
                <p>Review volume</p>
              </ul>
            </div>
            <div class="voc-volume-legend voc" v-if="csatRatings">
              <ul class="line-icon">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="130.221 187.116 34 11.14" width="30" height="10" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><path d="M161.22 191.69h-30" id="a"/><path d="M149.79 191.69c0 1.97-1.6 3.57-3.57 3.57-1.97 0-3.57-1.6-3.57-3.57 0-1.97 1.6-3.57 3.57-3.57 1.97 0 3.57 1.6 3.57 3.57z" id="b"/></defs><use xlink:href="#a" fill="#583fcc"/><use xlink:href="#a" fill-opacity="0" stroke="var(--avg-rating-symbol-line)"/><g><use xlink:href="#b" fill="var(--avg-rating-symbol-circle)"/></g></svg>
                <p>Avg. rating</p>
              </ul>
            </div>
      </div> -->
      <div
      class="review-volume bold" v-if="!npsCard">
        <p>{{vocVolume}}</p>
        <div>{{ vocVolume == 1 ? " Review" : " Reviews" }}</div>
      </div>
    </div>
    <template v-if="!npsCard">
      <div
        class="doughnut"
        v-if="vocData.summaryData">
        <doughnut-chart
          :id="id"
          :chartData="vocData.summaryData"
          :csatRatings="csatRatings ? Number(csatRatings.toFixed(1)) : null"
          :productName="product.name" />
      </div>
      <div class="recommendation-score" v-if="this.vocData && this.vocData.summaryData && !loading">
        <p>Likely to Recommend<span>{{this.vocData.summaryData.total? (Math.round((this.vocData.summaryData.recommendation_score/this.vocData.summaryData.total)*100)+'% ') :'N/A'}}</span></p>
      </div>
      <div v-if="loading" class="local-loader-container">
        <div class="inava-loader">
      </div>
    </div>
    </template>
    <template v-if="npsCard && recommendationData.total">
      <tree-map :id="product.id" :productName="product.name" :data="recommendationData" :key="recommendationData.total" />
      <div class="percentage-view">
        <div class="percentage-label">Detractors</div>
        <div class="percentage-progress">
          <b-progress class="detractors" :value="detractorValue" />
          <div class="percentage-value">{{detractorValue}}%</div>
        </div>
      </div>
      <div class="percentage-view">
        <div class="percentage-label">Passives</div>
        <div class="percentage-progress">
          <b-progress class="passives" :value="passiveValue" />
          <div class="percentage-value">{{passiveValue}}%</div>
        </div>
      </div>
      <div class="percentage-view">
        <div class="percentage-label">Promoters</div>
        <div class="percentage-progress">
          <b-progress class="promoters" :value="promoterValue" />
          <div class="percentage-value">{{promoterValue}}%</div>
        </div>
      </div>
    </template>
    <div
    class="recommendation-score"
    v-if="recommendationData.total && !loading">
      <p>Likely to Recommend<span>{{promoterValue ? promoterValue+'% ' :'N/A' }}</span></p>
    </div>
    <div class="no-review" v-if="isEmptySummary && !loading">
      <p><b-icon class="nodata-icon" icon="information-outline"></b-icon></p>
      No reviews for {{product.name}}
      <p>for the selection</p>
    </div>
    <div class="no-review" v-if="isEmptyNps && !loading">
      <p><b-icon class="nodata-icon" icon="information-outline"></b-icon></p>
      Recommendation score for {{product.name}}
      <p>is not available for the selection</p>
    </div>
    <!-- <template v-if="!npsCard">
      <div
        class="csat-trend"
        v-if="vocData.trendData && vocData.trendData.length">
        <bar-chart
          :chartData="vocData.trendData"
          :id="id"
          :avgRating="csatRatings"
          :productName="product.name"
          :productId="product.id" />
      </div>
    </template> -->
  </div>
</template>

<script>
import DoughnutChart from './DoughnutChart.vue'
import { mapActions, mapState } from 'vuex'
import TreeMap from '../NPS/TreeMap.vue'

/**
 * This component is used to render the cards seen on the **VocSummary** and **NPS** pages
 */
export default {
  components: {
    DoughnutChart,
    TreeMap
  },
  props: {
    /**
     * ID for each card
     */
    id: {
      type: Number,
      default: 1
    },
    /**
     * Product whose information is displayed
     */
    product: {
      type: Object
    },
    /** Indicates whether the card is being rendered for the NPS page */
    npsCard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      vocData: {},
      isEmptySummary: false,
      isEmptyNps: false,
      recommendationData: {},
      loading: false
    }
  },
  computed: {
    ...mapState('filters', [
      'productList',
      'selectedSourceList',
      'selectedProducts',
      'subCategories',
      'ActiveVocTimePeriod',
      'categoryList',
      'selectedIndustries',
      'selectedCompanies',
      'sortedProducts',
      'selectedCategories'
    ]),
    ...mapState('common', ['user_preference_mode']),
    ...mapState('user', ['userDetails']),
    ...mapState('vocsummary', [
      'vocStatictics'
    ]),
    csatRatings () {
      if (this.vocData && this.vocData.summaryData) {
        return this.vocData.summaryData.csat_ratings
      } else {
        return 0
      }
    },
    vocVolume () {
      if (this.vocData && this.vocData.summaryData) {
        return this.vocData.summaryData.review_volume
      } else {
        return 0
      }
    },
    detractorValue () {
      return Math.round(this.recommendationData.detractorTotal / this.recommendationData.total * 100)
    },
    passiveValue () {
      return Math.round(this.recommendationData.passiveTotal / this.recommendationData.total * 100)
    },
    promoterValue () {
      return Math.round(this.recommendationData.promoterTotal / this.recommendationData.total * 100)
    }
  },
  watch: {
    product: {
      immediate: true,
      handler () {
        this.vocData = {}
        this.isEmptySummary = false
        if (this.$route.name === 'summary') {
          this.getStatistics()
        }
        if (this.$route.name === 'nps') {
          this.getNPS()
        }
      }
    }
  },
 
  methods: {
    ...mapActions('vocsummary', [
      'getVocStatistics',
      'getNPSData',
    ]),
    /**
     * This method gets the data required to render the cards for the VocSummary view
     */
    async getStatistics () {
      this.loading = true
      if (
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.subCategories.length &&
        this.selectedIndustries.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.ActiveVocTimePeriod.range[0] &&
        this.ActiveVocTimePeriod.range[1] &&
        this.ActiveVocTimePeriod.group_by
      ) {
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          // product: this.selectedProducts.filter((obj) => Number(obj.id) === this.product.id)[0],
          product: this.selectedProducts.find((obj) => Number(obj.id) === this.product.id),
          categories: this.subCategories,
          industries: this.selectedIndustries.map((item) => item.id),
          companies: this.selectedCompanies.map((item) => item.id),
          clientId: this.userDetails.clientId,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          groupBy: this.ActiveVocTimePeriod.group_by,
          subscriptionId: this.selectedCategories.subscriptionId,
          // requestingModuleId: this.userDetails.modules.filter(m => m.module_name === 'voc')[0].module_id
          requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voc').module_id
        }
        const res = await this.getVocStatistics(data)
        this.loading = false
        if (res.trendData && res.trendData.length) {
          this.isEmptySummary = false
        } else {
          this.isEmptySummary = true
        }
        this.vocData = res
        // this.$emit('data')
      } else {
        this.isNoData = true
        // this.$emit('noData')
      }
    },
    /**
     * This method gets the data required to render the cards for the NPS view
     */
    async getNPS () {
      if (
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.subCategories.length &&
        this.selectedIndustries.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.ActiveVocTimePeriod.range[0] &&
        this.ActiveVocTimePeriod.range[1] &&
        this.ActiveVocTimePeriod.group_by
      ) {
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          // product: this.selectedProducts.filter((obj) => Number(obj.id) === this.product.id)[0],
          product: this.selectedProducts.find((obj) => Number(obj.id) === this.product.id),
          categories: this.subCategories,
          industries: this.selectedIndustries.map((item) => item.id),
          companies: this.selectedCompanies.map((item) => item.id),
          clientId: this.userDetails.clientId,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          groupBy: this.ActiveVocTimePeriod.group_by,
          subscriptionId: this.selectedCategories.subscriptionId,
          // requestingModuleId: this.userDetails.modules.filter(m => m.module_name === 'voc')[0].module_id
          requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voc').module_id
        }
        const res = await this.getNPSData(data)
        if (res.total) {
          this.isEmptyNps = false
        } else {
          this.isEmptyNps = true
        }
        this.recommendationData = res
        /**
         * This event is used to signify that the minimum data needed to render the view was successfully retrieved from the database.
         */
        this.$emit('data')
      } else {
        this.isNoData = true
        /**
         * This event is triggered when the minimum data needed to render the view is not available.
         */
        this.$emit('noData')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .voc-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .local-loader-container {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      background: var(--primary);
      border-radius: 4px;
      z-index: 5;
    }
    .doughnut {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .product-logo {
      height: 49px;
      display: flex;
      align-items: center;
      img {
        width: 120px;
        height: 49px;
        object-fit: contain;
        color: var(--chart-top-axis-color);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .product-name {
        color: var(--chart-top-axis-color);
        align-self: center;
        text-overflow: ellipsis;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
      }
      .fainted-logo {
        opacity: 1;
      }
    }
    .hide-overflow {
      width: calc(100% - 75px);
    }
    .w-100 {
      width: 100%;
    }
    .fixed-width {
      width: 120px;
    }
    .fainted-logo {
      opacity: 0.4;
    }
    .recommendation-score {
      background: rgba(245, 119, 119, 0.2);
      background: var(--recommendation-score-background-color);
      text-align: left;
      padding: 10px 18px;
      display: flex;
      margin-top: auto;
      color: var(--recommendation-score-text-color);
      p {
        display: flex;
        opacity: 1;
        width: 100%;
        align-items: center;
        font-weight: 500;
      }
      span {
        opacity: 1;
        color: var(--recommendation-score-font-color);
        font-weight: 700;
        margin-left: auto;
        display: flex;
        font-size: 40px;
      }
    }
  }
  .border-bottom {
    border-bottom: 1px solid #C7C7C7;
  }
  .justify-content-center {
    justify-content: center;
  }
  .review-volume-main {
    display: flex;
    padding: 0px 0px 15px 0px ;
    justify-content: space-between;
    .review-volume {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-family: Quicksand;
      font-size: 16px;
      line-height: 1;
      color: var(--review-volume-text);
      opacity: 1;
      p {
        color: var(--review-volume-number);
        opacity: 0.8;
        font-size: 26px;
        font-weight: 700;
      }
    }
  }
  .voc-legends {
    display: flex;
    justify-content: center;
    .voc-volume-legend {
      display: flex;
      align-items: center;
      .icons path {
            fill: var(--voc-bar-background-color);
            fill-opacity: 1;
          }
      p {
        margin: 0px;
        color: #34343d;
        font-family: Quicksand, sans-serif;
        font-weight: 500;
        padding-left: 10px;
        font-size: 12px;
        color: var(--fourth-text-color);
      }
      ul {
        list-style-type: none;
        display: flex;
        padding: 0px;
        margin: 0px;
        li {
          width: 10px;
          margin-right: 3px;
          height: 10px;
          background: #dadae4;
          border-radius: 4px;
          opacity: 1;
        }
      }
      .line-icon {
        padding-left: 20px;
      }
    }
  }
  .csat-trend {
    padding: 20px 0px 5px;
    margin: auto 0;
    display: flex;
  }
  .no-review {
    color :var(--secondary-text-color);
    opacity: 0.4;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    .nodata-icon {
      padding-bottom: 20px;
    }
  }
  .percentage-label {
    font-family: 'Quicksand', sans-serif;
    color: var(--nps-percentage-label-text-color);
    text-align: left;
    margin-bottom: 2px;
    font-size: 0.875rem;
  }
  .percentage-value {
    font-size: 0.75rem;
    color: var(--nps-percentage-value);
    font-weight: 700;
  }
  ::v-deep .percentage-progress {
    display: flex;
    .progress-wrapper{
      width: 85%;
      margin-right: 10px;
      border-radius: 0 !important;
        margin-bottom: 1rem !important;
      .progress {
        border-radius: 0 !important;
        height: 18px !important;
      }
      .progress::-webkit-progress-bar,
      .progress-wrapper.is-not-native::-webkit-progress-bar {
        background-color: var(--voc-split-border-color);
      }
    }
  }

::v-deep .promoters {
    .progress::-webkit-progress-value,
    .progress-wrapper.is-not-native::-webkit-progress-value {
      background-color: var(--inava-primary);
    }
    .progress::-moz-progress-bar,
    .progress-wrapper.is-not-native::-moz-progress-bar {
      background-color: var(--inava-primary);
    }
    .progress::-ms-fill,
    .progress-wrapper.is-not-native::-ms-fill {
      background-color: var(--inava-primary);
      border: none;
    }
}

::v-deep .passives {
    .progress::-webkit-progress-value,
    .progress-wrapper.is-not-native::-webkit-progress-value {
      background-color: var(--inava-primary-light);
    }
    .progress::-moz-progress-bar,
    .progress-wrapper.is-not-native::-moz-progress-bar {
      background-color: var(--inava-primary-light);
    }
    .progress::-ms-fill,
    .progress-wrapper.is-not-native::-ms-fill {
      background-color: var(--inava-primary-light);
      border: none;
    }
}

::v-deep .detractors {
    .progress::-webkit-progress-value,
    .progress-wrapper.is-not-native::-webkit-progress-value {
      background-color: var(--inava-pink);
    }
    .progress::-moz-progress-bar,
    .progress-wrapper.is-not-native::-moz-progress-bar {
      background-color: var(--inava-pink);
    }
    .progress::-ms-fill,
    .progress-wrapper.is-not-native::-ms-fill {
      background-color: var(--inava-pink);
      border: none;
    }
}

@media screen and (max-width: 1800px) {
  .voc-card {
    .recommendation-score {
      p {
        font-size: 14px;
      }
      span {
        font-size: 20px;
      }
    }
    .product-logo {
      img {
        width: 100px;
        height: 40px;
      }
    }
    .review-volume-main {
      padding: 0px 0px 7px 0px ;
        .review_volume{
        font-size: 13px;
        p {
          font-size: 20px;
        }
      }
    }
    .voc-legends {
      .voc-volume-legend {
        .icons {
          width: 13px;
        }
        p {
          padding-left: 5px;
          font-size: 11px;
        }
        ul {
          li {
            background: var(--inava-primary-dark);
          }
        }
        .line-icon {
          padding-left: 15px;
        }
      }
    }
  }
  .barchart {
    .no-review {
      margin-top: 5px;
    }
    .csat-trend {
      padding: 0px 0px 5px;
      margin: auto 0;
      display: flex;
    }
  }
}
</style>
