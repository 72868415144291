<template>
    <svg
      :id="'donut' + productName.replace(/[^a-zA-Z0-9]/g, '')"></svg>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState, mapActions } from 'vuex'
import * as d3 from 'd3'
import tooltip from '../../util/tooltip'
import { ratingList } from '../../constant/data'

/**
 * This component renders a doughnut chart which shows the distribution of reviews according to the rating per review. It also displays the average rating of all reviews of a product in the center of the chart
 */
export default {
  name: 'VoCDoughnut',
  props: {
    /**
     * Id for the chart
     */
    id: {
      type: Number,
      default: 1
    },
    /**
     * The rating displayed in the center of the doughnut
     */
    csatRatings: {
      type: Number
    },
    /**
     * The data for the chart
     */
    chartData: {
      type: Object
    },
    /**
     * The name of the product for which the chart is being rendered
     */
    productName: {
      type: String
    }
  },
  data () {
    return {
      observer: null
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedSourceList',
      'selectedProducts',
      'selectedCategories',
      'ActiveVocTimePeriod'
    ]),
    ...mapState('vocsummary', ['vocStatictics'])
  },
  watch: {
    vocStatictics () {
      this.generateChart()
    },
    chartData () {
      this.generateChart()
    }
  },
  mounted () {
    this.resizeWindow()
    if (this.chartData) { this.generateChart() }
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('filters', [
      'updateSelectedRating',
      'updateSelectedProducts'
    ]),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.chartData) {
          if (d3.select('#donut' + this.productName.replace(/[^a-zA-Z0-9]/g, '')).node() != null) { this.generateChart() }
        }
      })
      this.observer.observe(d3.select('#donut' + this.productName.replace(/[^a-zA-Z0-9]/g, '')).node().parentNode.parentNode)
    },
    /**
     * This method calculates the percentage distribution of reviews based on their ratings. The reviews are distributed into 3 groups *(Satisfied, Neutral, Dissatisfied)*. The information calculated here is displayed in the labels for the chart
     * @public
     */
    prepareData () {
      if (this.chartData.csat_ratings) {
        return [
          {
            percentage: Number((
              (this.chartData.satisfied_count /
                this.chartData.review_volume) *
              100
            ).toFixed(1)),
            type: 'satisfied',
            reviewCount: this.chartData.satisfied_count
          },
          {
            percentage: Number((
              (this.chartData.nutral_count /
                this.chartData.review_volume) *
              100
            ).toFixed(1)),
            type: 'neutral',
            reviewCount: this.chartData.nutral_count
          },
          {
            percentage: Number((
              (this.chartData.dissatisfied_count /
                this.chartData.review_volume) *
              100
            ).toFixed(1)),
            type: 'dissatisfied',
            reviewCount: this.chartData.dissatisfied_count
          },
          {
            percentage: Number((this.chartData.review_volume - (this.chartData.satisfied_count + this.chartData.dissatisfied_count + this.chartData.nutral_count)) / this.chartData.review_volume * 100).toFixed(1),
            type: 'none',
            reviewCount: this.chartData.review_volume - (this.chartData.satisfied_count + this.chartData.dissatisfied_count + this.chartData.nutral_count)
          }
        ]
      } else {
        return [
          {
            percentage: Number(100).toFixed(1),
            type: 'none',
            reviewCount: this.chartData.review_volume
          }
        ]
      }
    },
    /**
     * This method renders the Doughnut chart
     * @public
     */
    generateChart () {
      const doughnutData = this.prepareData()
      const productName = this.productName
      const did = '#donut' + this.productName.replace(/[^a-zA-Z0-9]/g, '')
      d3.selectAll(did + ' > *').remove()

      const width = d3.select(did).node().clientWidth
      const height = d3.select(did).node().clientHeight
      const svg = d3.select(did)
        .attr('width', width)
        .attr('height', height)
        .append('g')

      svg.append('g')
        .attr('class', 'labels')
      svg.append('g')
        .attr('class', 'lines')

      const radius = Math.min(width, height) / 2 - 25

      const pie = d3.pie().sort(null).value(function (d) {
        return d.percentage
      })
      // Values came through experimentation. They control the thickness of the donut
      const arc = d3.arc().innerRadius(radius * 0.45).outerRadius(radius * 0.63)

      // These values are used to calculate label positioning
      const outerArc = d3.arc()
        .outerRadius(radius * 0.9)
        .innerRadius(radius * 0.9)

      const getPathColor = function (type, text = 'arc') {
        if (type === 'satisfied') {
          return 'var(--inava-primary)'
        }
        if (type === 'neutral') {
          if (text === 'text') return 'var(--donut-label-light)'
          return 'var(--inava-primary-light)'
        }
        if (type === 'dissatisfied') {
          return 'var(--inava-pink)'
        }
        if (type === 'none') {
          return 'var(--null-donut-sector-color)'
        }
      }
      svg.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')

      const data = doughnutData
      const csatRatings = this.csatRatings

      const getBucket = function (type) {
        switch (type) {
          case 'satisfied':
            return '4-5 Stars'
          case 'neutral':
            return '3 Stars'
          case 'dissatisfied':
            return '1-2 Stars'
          case 'none':
            return 'No Rating'
        }
      }

      const forwardOnClick = (event, d, type) => {
        let selected = []
        switch (type) {
          case 'satisfied':
            selected = [ratingList[4], ratingList[5]]
            break
          case 'neutral':
            selected = [ratingList[3]]
            break
          case 'dissatisfied':
            selected = [ratingList[1], ratingList[2]]
            break
          case 'none':
            selected = [ratingList[0]]
            break
        }
        const output = this.selectedProducts.filter(({ name }) => name === this.productName)
        this.$router.push({ name: 'comments' })
        if (output.length) {
          this.updateSelectedProducts([output[0]])
        }
        this.updateSelectedRating(selected)
        tooltipFun(event, d, 'out')
      }

      function tooltipFun (event, d, type) {
        let data = {}
        switch (type) {
          case 'in':
            data = {
              'Review volume': d.data.reviewCount,
              'Rating': getBucket(d.data.type)
            }
            break
        }
        tooltip(event, data, type)
      }

      svg.selectAll('path')
        .data(pie(data))
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('stroke', function (d) {
          return d.data.percentage === 100 || d.data.percentage === 0
            ? 'none'
            : 'none'
        })
        .attr('fill', function (d) {
          return getPathColor(d.data.type)
        })
        .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
        .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
        .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))
        .on('click', (event, d) => forwardOnClick(event, d, d.data.type))

      d3.select('.flex-seven').on('scroll', () =>
        d3.selectAll('.tooltip').style('display', 'none')
      )

      function getXPosition (d) {
        return midAngle(d) < Math.PI ? 1.05 : -1.05
      }

      function getYPosition (d, i) {
        const pos = outerArc.centroid(d)
        const percent = (d.endAngle - d.startAngle) / (2 * Math.PI) * 100
        if (percent < 20) {
          pos[1] += i * -1
        }
        if (percent >= 20 && percent < 40) {
          pos[1] += i * -5
        }
        return pos[1]
      }

      function midAngle (d) { return d.startAngle + (d.endAngle - d.startAngle) / 2 }

      svg.select('.lines')
        .selectAll('polyline')
        .data(pie(data))
        .enter()
        .append('polyline')
        .attr('class', `polyline ${this.productName}`)
        .attr('points', function (d, i) {
          if (d.data.reviewCount !== 0) {
            const pos = outerArc.centroid(d, i)
            // Came across this value through experimentation. Used to position the label horizontally
            pos[0] = radius * 0.6 * getXPosition(d)
            if (pos[1] > -60 && pos[1] < 50) {
              return [arc.centroid(d), arc.centroid(d), pos]
            }
            if (d.data.percentage === 0) {
              return null
            } else {
              return [arc.centroid(d), outerArc.centroid(d), pos]
            }
          }
        })
        .attr('stroke', 'var(--voc-line-color)')
        .attr('stroke-width', '1px')
        .attr('fill', 'none')
        .attr('opacity', '0.3')

      svg.select('.labels').selectAll('text')
        .data(pie(data))
        .enter()
        .append('text')
        .attr('transform', function (d, i) {
          const pos = outerArc.centroid(d)
          // Came across this value through experimentation. Used to position the label horizontally
          pos[0] = radius * 0.6 * getXPosition(d)
          pos[1] = getYPosition(d, i)
          return 'translate(' + pos + ')'
        })
        .attr('class', `label-text ${this.productName}`)
        .append('tspan')
        .attr('class', 'group-legends')
        .style('display', 'block')
        .attr('fill', function (d) {
          return getPathColor(d.data.type, 'text')
        })
        .attr('stroke', 'none')
        .attr('text-anchor', 'middle')
        // On a screen with a res of 1920x1080 pixels, this calculation gives a font-size approx equal to 18px. For smaller screens the size scales according to the donut radius, which itself is dependent on the viewport width
        .style('font-size', 0.176 * radius)
        .style('font-weight', '600')
        .style('fill-opacity', 0.8)
        .text(function (d) {
          if (d.data.reviewCount !== 0) {
            return d.data.percentage === 0 ? '' : d.data.percentage + '%'
          }
        })
        .style('text-anchor', function (d) {
          return getXPosition(d) >= 1 ? 'start' : 'end'
        })

      svg.selectAll('.label-text')
        .append('tspan')
        .attr('class', 'group-legends')
        .attr('fill', 'var(--secondary-text-color)')
        .style('display', 'block')
        .attr('stroke', 'none')
        .attr('font-family', 'Quicksand')
        .style('font-size', function (d) {
          if (d.data.type === 'none') {
            // On a screen with a res of 1920x1080 pixels, this calculation gives a font-size slightly more than 13px. For smaller screens the size scales according to the donut radius, which itself is dependent on the viewport width
            return 0.125 * radius
          }
          // On a screen with a res of 1920x1080 pixels, this calculation gives a font-size equal to 18px. For smaller screens the size scales according to the donut radius, which itself is dependent on the viewport width
          return 0.128 * radius
        })
        .style('font-weight', 500)
        .style('fill-opacity', 0.8)
        .attr('dy', 0.15 * radius)
        .attr('x', 0)
        .text(function (d) {
          if (d.data.reviewCount !== 0) {
            return d.data.percentage === 0 ? '' : getBucket(d.data.type)
          }
        })
        .style('text-anchor', function (d) {
          return getXPosition(d) >= 1 ? 'start' : 'end'
        })

      if (this.chartData.csat_ratings) {
        svg
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('class', 'center-text')
          .attr('y', 0)
          .attr('font-weight', 'normal')
          .attr('font-family', 'Quicksand')
          // On a screen with a res of 1920x1080 pixels, this calculation gives a font-size approx equal to 30px. For smaller screens the size scales according to the donut radius, which itself is dependent on the viewport width
          .attr('font-size', 0.288 * radius)
          .attr('font-weight', 700)
          .attr('fill', 'var(--voc-line-color)')
          .text(csatRatings)
      }

      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('class', ' csat')
        .attr('font-weight', 'normal')
        // On a screen with a res of 1920x1080 pixels, this calculation gives a font-size approx equal to 13px. For smaller screens the size scales according to the donut radius, which itself is dependent on the viewport width
        .attr('font-size', 0.128 * radius)
        .attr('font-family', 'Quicksand')
        .attr('y', () => {
          if (!this.chartData.csat_ratings) {
            return 5
          }
          return 0.224 * radius
        })
        .attr('fill', 'var(--voc-line-color)')
        .text(() => {
          if (!this.chartData.csat_ratings) {
            return 'No Ratings'
          }
          return 'out of 5'
        })

      const labelTexts = d3.selectAll('.label-text')
      const polylines = document.querySelectorAll('.polyline')
      const allLabels = document.querySelectorAll('.label-text')
      let prev
      // Remove label text and polylines if alternate labels overlap
      labelTexts.each(function (d, i) {
        if (i > 1) {
          const thisBCR = this.getBoundingClientRect()
          const prevBCR = prev.getBoundingClientRect()
          if (!(thisBCR.right < prevBCR.left ||
            thisBCR.left > prevBCR.right ||
            thisBCR.bottom < prevBCR.top ||
            thisBCR.top > prevBCR.bottom)) {
            this.remove()
            polylines[i].remove()
          }
        }
        prev = allLabels[i - 1]
      })

      // // From the remaining labels, if any consecutive labels overlap, then this gets rid of those
      labelTexts.each(function (d, i) {
        if (i > 0) {
          const thisBCR = this.getBoundingClientRect()
          const prevBCR = prev.getBoundingClientRect()
          if (!(thisBCR.right < prevBCR.left ||
            thisBCR.left > prevBCR.right ||
            thisBCR.bottom < prevBCR.top ||
            thisBCR.top > prevBCR.bottom)) {
            this.remove()
            polylines[i].remove()
          }
        }
        prev = this
      })
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
  height: 100%;
}
</style>
