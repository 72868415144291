<template>
  <div :id="'tree-map-container-'+ id" class="mt-2"></div>
</template>

<script>
// import { mapState, mapActions } from 'vuex'
import * as d3 from 'd3'
import { mapActions, mapState } from 'vuex'
import tooltip from '../../util/tooltip'

export default {
  name: 'TreeMap',
  props: {
    /**
     * The id for each tree-map
     */
    id: {
      type: Number
    },
    /**
     * The data for each tree-map
     */
    data: {
      type: Object
    }
  },
  data () {
    return {
      observer: null
    }
  },
  computed: {
    ...mapState('filters', [
      'productList',
      'selectedSourceList',
      'selectedProducts',
      'subCategories',
      'ActiveVocTimePeriod',
      'categoryList',
      'loading',
      'selectedIndustries',
      'selectedCompanies',
      'sortedProducts',
      'selectedCategories'
    ])
  },
  mounted () {
    this.resizeWindow()
    if (this.data) { this.renderChart() }
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    // ...mapActions('vocsummary', ['getNPSData']),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.data) {
          if (document.querySelector(`#tree-map-container-${this.id}`) != null) { this.renderChart() }
        }
      })
      this.observer.observe(document.querySelector(`#tree-map-container-${this.id}`).parentNode.parentNode)
    },
    /** Method used to render the SVG.
     * @public
    */
    renderChart () {
      d3.select(`#tree-map-container-${this.id} > *`).remove()

      // set the dimensions and margins of the graph
      const containerWidth = document.querySelector(`#tree-map-container-${this.id}`).clientWidth
      const margin = { top: 0, right: 0, bottom: 0, left: 0 }
      const width = containerWidth - margin.left - margin.right
      const height = containerWidth - margin.top - margin.bottom - 75

      // append the svg object to the body of the page
      const svg = d3
        .select(`#tree-map-container-${this.id}`)
        .append('svg')
        .attr('class', 'treemap')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)
      // console.log(document.querySelector('.treemap'))

      function createTreeMap (data) {
        const root = d3.hierarchy(data)
          .eachBefore(function (d) { d.data.id = (d.parent ? d.parent.data.id + '.' : '') + d.data.name })
          .sum(function (d) { return +d.value })
          .sort()
        // root.sum(function (d) {
        //   return +d.value;
        // }); // Compute the numeric value for each entity

        // Then d3.treemap computes the position of each element of the hierarchy
        // The coordinates are added to the root object above
        d3.treemap().tile(d3.treemapBinary).size([width, height]).padding(2)(root)

        // use this information to add rectangles:
        svg
          .selectAll('rect')
          .data(root.leaves())
          .join('rect')
          .attr('x', function (d) {
            return d.x0
          })
          .attr('y', function (d) {
            return d.y0
          })
          .attr('width', function (d) {
            return d.x1 - d.x0
          })
          .attr('height', function (d) {
            return d.y1 - d.y0
          })
          .style('fill', function (d) {
            if (d.data.name >= 8) {
              return 'var(--inava-primary)'
            } else if (d.data.name >= 5 && d.data.name < 8) {
              return 'var(--inava-primary-light)'
            } else if (d.data.name >= 0 && d.data.name < 5) {
              return 'var(--inava-pink)'
            } else {
              return 'transparent'
            }
          })
          .attr('fill', 'var(--voc-bar-background-color)')
          .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
          .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
          .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))

        function tooltipFun (event, d, type) {
          let data = {}
          switch (type) {
            case 'in':
              // eslint-disable-next-line no-case-declarations
              data = {
                'Type': d.parent.data.name,
                'Recommendation Score': `${d.data.name}.0`,
                'No. of reviews': `${d.data.value}`
              }
              break
          }
          tooltip(event, data, type)
        }

        // and to add the text labels
        svg
          .selectAll('text')
          .data(root.leaves())
          .join('text')
          .attr('x', function (d) {
            const rectWidth = d.x1 - d.x0
            if (rectWidth < width / 4) {
              return d.x0 + 3
            } else {
              return d.x0 + 10
            }
          }) // +10 to adjust position (more right)
          .attr('y', function (d) {
            const rectWidth = d.x1 - d.x0
            if (rectWidth < width / 4) {
              return d.y1 + 5
            } else { return d.y1 - 10 }
          }) // +20 to adjust position (lower)
          .text(function (d) {
            const rectWidth = d.x1 - d.x0
            const rectHeight = d.y1 - d.y0
            if (d.data.name !== 'Detractors') {
              if (rectHeight >= height / 3) {
                return `${d.data.name}.0, ${d.data.value}`
              } else if (rectWidth >= width / 3 && rectHeight >= 20) {
                return `${d.data.name}.0, ${d.data.value}`
              }
            }
          })
          .attr('transform', function (d) {
            const rectWidth = d.x1 - d.x0
            // const rectHeight = d.y1 - d.y0
            if (rectWidth < width / 4) {
              return 'rotate(-90)'
            } else { return '' }
          })
          .style('transform-box', 'fill-box')
          .attr('font-size', 0.0523 * width)
          .attr('fill', 'white')
      }
      if (this.data.total) {
        createTreeMap(this.data)
      } else {
        svg.append('text')
          .attr('x', width / 2)
          .attr('y', height / 2)
          .text('No data for this product')
          .attr('text-anchor', 'middle')
          .attr('fill', 'var(--tertiary-text-color)')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
